@import '../global/globalCssVariables.scss';

.container {
  width: 100%;
  position: relative;
  height: fit-content;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $light-tint;

  &.lm {
    background-color: $light-tint;
  }

  &.dm {
    background-color: $background-primary-dm;
  }
}

@media only screen and (max-width: 1000px) {
  .h1 {
    display: none !important;
  }

  .content {
    width: calc(100vw + 4px) !important;
  }
}

.content {
  width: 100%;
  height: fit-content;
}
