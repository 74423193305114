@import "../../global/globalCssVariables.scss";

.game-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: solid 2px $variant-brown;
}

.game-container {
  border-radius: $standard-radius;
  padding: 20px;
  max-width: 600px;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    border-radius: 0;
    border: none;
    max-width: none;
  }
}

.date {
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  .time,
  .location,
  .info,
  .date,
  .group {
    font-size: 11px;
  }

  .competition {
    display: none;
  }
}

.info {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .live {
    padding: 5px 10px;
    background-color: $variant-red-dm;
  }

  .competition {
    font-weight: 600;
  }
}

.score {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  font-style: normal;
  font-family: "poster-gothic-cond-atf", sans-serif;
}

.scorers {
  position: absolute;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
  top: 100%;
  display: flex;
  flex-direction: column;

  .scorer {
    white-space: nowrap;
  }
}

.location {
  font-style: italic;

  &.true {
    text-decoration: underline;
    text-transform: italic;
    cursor: pointer;
  }
}

.team {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.image {
  width: 70px;
  height: 70px;
}

.vs {
  width: 20%;
}

.score-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: relative;
}

.score-num {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.pens {
  font-size: 12px;
}
