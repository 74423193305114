@import "../../global/globalCssVariables.scss";

.recents-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: $light-tint;
  position: absolute;
  bottom: 45px;
  right: 40px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  .recent {
    border-radius: 50px;
    height: 50px;
    width: 50px;
    background: $variant-brown;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "bely-display", sans-serif;

    &.w {
      background: $variant-green;
    }

    &.l {
      background: $variant-red;
    }
  }

  .future {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 50px;
    padding: 0px 15px;
    gap: 6px;
    width: fit-content;
    background: $variant-red;
    cursor: pointer;
    transition: 0.5s ease;

    &:hover {
      transform: translate(5px, 0px);
    }
  }
}
