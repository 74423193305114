@import '../../global/globalCssVariables.scss';

.standings-container {
  height: 100%;
  overflow: scroll;

  .standings-content {
    width: 100%;
    padding-bottom: 150px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media only screen and (max-width: 1000px) {
      margin-top: 0px;
    }
  }
}
