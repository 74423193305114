@import "../../../global/globalCssVariables.scss";

.tableRow-container {
  display: grid;
  height: 30px;
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.37);
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 50px 50px 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}

.tableRow-container.true {
  border-bottom: solid 0.5px #ffee3a;
}

.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.rank {
  opacity: 0.5;
}

.do-l {
  align-items: flex-start;
  display: flex;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1000px) {
  .tableRow-container {
    grid-template-columns: 50px 30px 100px 1fr 1fr 1fr 1fr !important;
  }
  .do {
    display: none !important;
  }
}
