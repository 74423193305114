@import '../../global/globalCssVariables.scss';

.home-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: fit-content fit-content fit-content;
  height: fit-content;
  min-height: calc(100% - 224px);

  @media only screen and (max-width: 1000px) { 
    display: flex;
    flex-direction: column;
  }

  .box-button {
    cursor: pointer;
    width: calc(100% - 40px);
    background: $variant-light-tint-l1;
    display: grid;
    grid-template-columns: 30px calc(100% - 60px) 30px;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    margin-top: 20px;
    height: 30px;
    border-radius: 30px;
    transition: 0.5s ease;

    &.red {
      background: $variant-red-l1;
    }

    &:hover {
      transform: translate(5px, 0px);
    }

    .icon-start, .text {
      display: flex;
      justify-content: flex-start;
    }

    .icon-end {
      display: flex;
      justify-content: flex-end;
    }
  }

 

  .aboutUs-container, .joinTheTeam-container, .supportUs-container {
    padding: 40px;
  }

  .supportUs-container {
    background: $light-tint;
    color: $variant-brown;
    height: 100%;
  }

  .aboutUs-container {
    background: $variant-red;
    color: $light-tint;
    grid-row: 1 / 3;
    grid-column: 1;
  }

  .gallery-container {
    grid-row: 1;
    grid-column: 2;
    overflow: hidden;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      @media only screen and (max-width: 1000px) { 
        height: 100%;
        width: none;
      }

      width: 100%;
      object-fit: cover;
    }
  }

  .joinTheTeam-container {
    grid-row: 2 / 4;
    grid-column: 2;
    height: 100%;
    color: $light-tint;
    background: $variant-brown;
  }
}
