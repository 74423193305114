@import '../../global/globalCssVariables.scss';

.menuPanel-container {
  width: 100%;
  // padding: 10px;
  background: $variant-red;
  color: $light-tint;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: sticky;
  top: 0px;
  //   border: solid 1px $variant-brown-lm;
  // background: $background-tint;
  border-radius: $standard-radius;
  overflow-y: scroll;
  font-family: "poster-gothic-cond-atf", sans-serif;

  @media only screen and (max-width: 1000px) { 
    justify-content: flex-start;
    padding-left: 20px;
    margin-top: 0px;
    border-radius: 0;
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 1000px) {
  .fade {
    width: 200px;
    height: 100%;
    z-index: 10;
    position: absolute;
    pointer-events: none;
    transition: 0.5s ease;

    &.start {
      left: 0px;
      background: linear-gradient(90deg, $variant-red, rgba(0,0,0,0));
    }

    &.end {
      right: 0px;
      background: linear-gradient(270deg, $variant-red, rgba(0,0,0,0));
    }
  }
}