@import '../../global/globalCssVariables.scss';

.matches-container {
  overflow: scroll;
  height: 100%;
  width: 100%;
  position: relative;
}

.fixtures {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 1000px) { 
    padding-top: 0px;
    gap: 0px;
  }
}
