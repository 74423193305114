@import '../../global/globalCssVariables.scss';

.table-container {
  background-color: $light-tint;
  border-radius: $standard-radius;
  color: $color-primary-lm;
  padding: 20px;
}

.table-headers {
  display: grid;
  height: 30px;
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.37);
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 50px 50px 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}



.l {
  align-items: flex-start;
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .table-headers {
    grid-template-columns: 50px 130px 1fr 1fr 1fr 1fr !important;
  }
}

.group-header {
  width: 100%;
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 10px;
}
