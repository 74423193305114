@import '../../global/globalCssVariables.scss';

.statBox-container {
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    

  &:hover {
  }

  .stat {
    font-size: 4rem;
  }

  .description {
    font-size: 1rem;
    transform: translate(0px, -10px);
  }

  // @media only screen and (max-width: 1000px) {
  //   .stat {
  //     font-size: 3rem;
  //   }
  
  //   .description {
  //     font-size: 0.7rem;
  //   }
  // }
}


