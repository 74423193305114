@import "../../global/globalCssVariables.scss";

.player-card {
  color: $color-primary-lm;
  background: $item-background;
  // box-shadow: 0px 10px 15px #00000029;
  // border: 1px solid $variant-red;
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  border-radius: 10px;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }

  .player-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: 50% 0px;
  }

  .info-wrapper {
    position: absolute;
    width: calc(100%);
    bottom: 0px;

    .player-info {
      background: $variant-red;
      color: $light-tint;
      width: calc(100% - 20px);
      padding: 10px;
      height: fit-content;
      border-radius: 0px;

      .player-name {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .player-class {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        text-align: left;
      }

      .player-hometown {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        text-align: left;
      }

      .player-positions {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        text-align: left;
      }
    }
  }
}
