@import "../../global/globalCssVariables.scss";

.header {
  background-color: $light-tint;
  position: sticky;
  top: 0px;
  padding: 40px;
  width: calc(100% - 80px);
  height: fit-content;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  @media only screen and (max-width: 1000px) {
    padding: 10px 12px;
    height: 120px;
    width: calc(100% - 20px);
  }

  &.home {
    @media only screen and (min-width: 1000px) {
      justify-content: flex-start;

      .title {
        flex-direction: column;
        gap: 0px;

        h1 {
          font-size: 80px;
        }

        h2 {
          font-size: 40px;
          // transform: translate(0px, -20px);
        }
      }
    }
  }

  .title {
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    font-weight: bold;
    font-family: "bely-display", sans-serif;
    font-style: normal;
    color: $variant-brown-lm;
    text-align: left;

    h1 {
      font-weight: 600;
      color: $variant-brown;
    }

    h2 {
      color: $variant-red;
    }

    h1,
    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0px;
      font-size: 20px;
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      padding: 10px 10px;
      width: calc(100% - 20px);
      gap: 0px;
      justify-content: center;
    }

    .logo {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    @media only screen and (max-width: 1000px) {
      h1 {
        font-size: 25px !important;
      }
    }

    .h2 {
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 1000px) {
    .fade {
      width: 200px;
      height: 60px;
      bottom: 0px;
      z-index: 10;
      position: absolute;
      pointer-events: none;
      transition: 1.2s ease;

      &.start {
        left: 10px;
        background: linear-gradient(90deg, $light-tint, rgba(0, 0, 0, 0));
      }

      &.end {
        right: 10px;
        background: linear-gradient(270deg, $light-tint, rgba(0, 0, 0, 0));
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: center;
    align-items: center;
    z-index: 5;
    gap: 10px;
    position: absolute;
    height: 40px;
    top: 40px;
    right: 40px;

    @media only screen and (max-width: 1000px) {
      position: relative;
      flex-flow: row;
      top: 0;
      left: 0;
      justify-content: flex-start;
      width: 100%;
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .tab {
      text-transform: uppercase;
      font-size: 15px;
      color: $variant-brown;
      height: 100%;
      width: fit-content;
      padding: 0px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
      font-style: normal;
      font-family: "poster-gothic-cond-atf", sans-serif;
      background-color: $light-tint;
      transition: 0.5s ease;
      border-radius: 40px;

      &.true {
        @media only screen and (max-width: 1000px) {
          transform: translate(0px, 0px) !important;
        }
        transform: translate(0px, 3px) !important;
        background-color: $variant-red-l1;
        color: $variant-red;
      }

      &:hover {
        @media only screen and (max-width: 1000px) {
          transform: translate(0px, 0px) !important;
        }
        background-color: $variant-red-l1;
        transform: translate(0px, 1.5px);
      }
    }
  }
}
