@import "../../global/globalCssVariables.scss";

.team-container {
  height: calc(100% - 150px);
  padding-bottom: 50px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .note {
    padding: 10px 0px;
    width: 100%;
    background: $variant-brown;
    color: $light-tint;

    a {
      text-decoration: none;
      color: $light-tint;
      font-weight: 700;
      background: $variant-light-tint-l1;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .team {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: fit-content;

    @media only screen and (max-width: 2500px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 2000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    @media only screen and (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}
