@import '../../global/globalCssVariables.scss';

.stats-container {
  display: grid;

  .teamStats-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    // border: solid 1px $variant-brown-lm;
    font-weight: 600;
    font-style: normal;
    font-family: "poster-gothic-cond-atf", sans-serif;


    @media only screen and (max-width: 1500px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  }

  .tops {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  .topAssists-container {
    background: $variant-brown;
    color: $light-tint;
  }

  .topScorers-container {
    background: $variant-red;
    color: $light-tint;
  }

  .topScorers-container, .topAssists-container {
    width: calc(100% - 40px);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    .ranking-item {
      display: grid;
      grid-template-columns: 60px calc(100% - 80px) 20px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .num {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: $variant-light-tint-l1;
      }

      .name {
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "poster-gothic-cond-atf", sans-serif;
        font-size: 20px;
      }
    }
  }

}
