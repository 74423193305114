.App {
  text-align: center;
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  background-color: #fdf2ec;
  user-select: none;
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}
