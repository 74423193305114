/**
 COLORS
 dm = dark mode
 lm = light mode
*/
$light-tint: #fdf2ec;
$variant-light-tint-l1: #fdf2ec3b;
$variant-red: #a54343;
$variant-red-l1: #a543433b;
$variant-green: #15976c;
$variant-brown: #552527;
$variant-brown-l1: #3117183b;

// background color
$background-primary-dm: #1b1b27;
$background-primary-lm: #fdf2ec;

// background tint
$background-tint: #30181963;

$item-background: white;

// text color
$color-primary-dm: white;
$color-tint-dm: rgba(255, 255, 255, 0.603);

$color-primary-lm: black;
$color-tint-lm: rgba(255, 255, 255, 0.4);

// variants
$variant-red-dm: #df1e4d;
$variant-yellow-dm: #ffee3a;

$variant-red-lm: #c12026;
$variant-brown-lm: #301819;

// border radius
$standard-radius: 0px;
