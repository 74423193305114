@import '../../global/globalCssVariables.scss';

.button-container {
  text-transform: uppercase;
  font-size: 15px;
  color: $variant-brown;
  height: 40px;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-style: normal;
  font-family: "poster-gothic-cond-atf", sans-serif;
  background-color: $light-tint;
  transition: 0.5s ease;
  border-radius: 40px;
  white-space: nowrap;

  &.dark {
    background-color: $variant-red;
    color: $light-tint;

    &.true {
      background-color: $variant-light-tint-l1;
      color: $light-tint;
    }
  
    &:hover {
      background-color: $variant-light-tint-l1;
    }
  }

  &.true {
    background-color: $variant-red-l1;
    color: $variant-red;
  }

  &:hover {
    background-color: $variant-red-l1;
  }
}